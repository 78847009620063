import React, { useState } from "react";
import ContactPageModal from "../../screens/Contact_Us/CITBContactForm/ContactFormCitb";

const Popup = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-2xl shadow-2xl w-96 md:w-1/3 text-center">
        {/* Offer Badge */}
        <div className="bg-[#FF3131] text-white text-sm font-bold py-1 px-3 rounded-full inline-block mb-3">
          Amazing Discount Alert!
        </div>

        {/* Headline */}
        <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
          🎉 Unbeatable Deals on
          <span className="text-[#FF3131]"> CITB Courses!</span>
        </h2>

        {/* Description */}
        <p className="text-gray-700 mb-4 text-lg">
          Get certified at an all-time low price and boost your career! Choose the best deal that suits your schedule:
        </p>

      

        {/* Call to Action */}
        <div className="flex flex-col space-y-4">
          <a
            href="/citb-courses"
            className="bg-[#FF3131] hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-lg text-lg shadow-lg transition duration-300"
          >
            Grab Your Spot Now 🚀
          </a>
          <button
            className="text-gray-600 hover:text-gray-900 font-semibold py-2"
            onClick={onClose}
          >
            No, Thanks
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;