import React from "react";
import Icon from "../../assets/logo.png";
import visa from "./paymentsAssets/visa.svg";
import paypal from "./paymentsAssets/paypal.svg";
import googlepay from "./paymentsAssets/googlepay.png";
import applepay from "./paymentsAssets/applepay.png";
import stripe from "./paymentsAssets/stripe.webp";
import takepayments from "./paymentsAssets/takepayments.png";
import barclays from "./paymentsAssets/barclays.png";
import americanexpress from "./paymentsAssets/americanexpress.webp";
import mastercard from "./paymentsAssets/mastercard.png";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-white text-[#0C1524] py-10 px-6 lg:px-10">
      {/* Top Section */}
      <div className="flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
        {/* Logo Section */}
        <div className="flex flex-col md:w-1/4">
          <a href="/">
            <img src={Icon} alt="Logo" className="w-auto h-10 mb-4" />
          </a>
        </div>

        {/* Links Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full md:w-3/4">
          {/* Support */}
          <div>
            <h3 className="mb-4 text-lg font-bold">Support</h3>
            <ul className="space-y-2">
              <li><a href="/term-and-conditions" className="hover:underline">Terms of Service</a></li>
              <li><a href="/privacy-policy" className="hover:underline">Privacy Policy</a></li>
              <li><a href="/cookies-policy" className="hover:underline">Cookies Policy</a></li>
              <li><a href="/refund-policy" className="hover:underline">Refund Policy</a></li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="mb-4 text-lg font-bold">Company</h3>
            <ul className="space-y-2">
              <li><a href="/about-us" className="hover:underline">About Us</a></li>
              <li><a href="/nvq-level-courses" className="hover:underline">NVQ Courses</a></li>
              <li><a href="/citb-courses" className="hover:underline">CITB Courses</a></li>
              <li><a href="/careers" className="hover:underline">Join Our Team</a></li>
            </ul>
          </div>

          {/* Contact Us */}
          <div>
            <h3 className="mb-4 text-lg font-bold">Contact Us</h3>
            <ul className="space-y-2">
              <li>Email: <a href="mailto:sales@m2hse.co.uk" className="hover:underline">sales@m2hse.co.uk</a></li>
              <li>Phone: <a href="tel:02080599944" className="hover:underline">02080-599944</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-8 flex flex-col md:flex-row justify-between items-center border-t border-gray-300 pt-6 space-y-6 md:space-y-0">
        {/* Social Icons */}
        <div className="flex space-x-6 justify-center md:justify-start">
          <a href="https://www.facebook.com/profile.php?id=100090575422097" target="_blank" rel="noreferrer" className="hover:text-blue-600">
            <FaFacebook className="text-2xl" />
          </a>
          <a href="https://www.linkedin.com/company/m2hse-training" target="_blank" rel="noreferrer" className="hover:text-blue-700">
            <FaLinkedin className="text-2xl" />
          </a>
          <a href="https://www.instagram.com/m2hsetraining" target="_blank" rel="noreferrer" className="hover:text-pink-600">
            <FaInstagram className="text-2xl" />
          </a>
        </div>

        {/* Payments Section */}
        <div className="flex flex-wrap items-center justify-center gap-2 md:gap-4">
          <img src={visa} alt="Visa" className="w-16 h-12" />
          <img src={paypal} alt="PayPal" className="w-16 h-12" />
          <img src={googlepay} alt="Google Pay" className="w-20 h-12" />
          <img src={applepay} alt="Apple Pay" className="w-16 h-8" />
          <img src={stripe} alt="Stripe" className="w-14 h-6" />
          <img src={takepayments} alt="Takepayments" className="w-24 h-16" />
          <img src={mastercard} alt="Mastercard" className="w-16 h-8" />
          <img src={barclays} alt="Barclays" className="w-24 h-16" />
          <img src={americanexpress} alt="American Express" className="w-24 h-12" />
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-8 text-center text-sm font-medium space-y-2">
        <p className="leading-relaxed">
          Address: M2HSE Training Ltd, Ceme Business Campus, Marsh Way, Rainham, RM13 8EU
        </p>
        <p>COPYRIGHT © 2024 M2HSE</p>
        <p>Company Registration No: 12804208 | VAT Number: 357511104</p>
      </div>
    </div>
  );
};

export default Footer;
