import React, { useEffect, useRef, useState } from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom/dist";
import TawkToScript from "./TawkToScript";
import axios from "axios";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { useCart } from "./CartContext";
import Popup from "./components/popUp/PopUp";
import usePageTitle from "./components/PageTitle/PageTitle";
import ContactPopUp from "./components/popUp/ContactPopUp";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

ReactGA.initialize("G-66PCR1F99J");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
ReactPixel.init("YOUR_PIXEL_ID");
ReactPixel.pageView();

function App() {
  const location = useLocation();
  const { showCart } = useCart();
  const [showPopup, setShowPopup] = useState(false);
  const [showContactUs, setshowContactUs] = useState(false);
  const contactIntervalRef = useRef(null); 
  const contactTimeoutRef = useRef(null)

  useEffect(() => {
    const hasPopupBeenShown = sessionStorage.getItem("popupShown");
    if (!hasPopupBeenShown) {
      const firstTimeout = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem("popupShown", "true"); 
      }, 5000);
  
      return () => clearTimeout(firstTimeout);
    }
  }, []);
 

useEffect(() => {
    const maxCount = 2;
    let count = parseInt(sessionStorage.getItem("contactPopupCount")) || 0;
    if (count >= maxCount) {
        return;
    }
    const showAndStartOneTimeInterval = () => {
        setshowContactUs(true);
        count++;
        sessionStorage.setItem("contactPopupCount", count);
        if (count === 1) {
            console.log("Setting 5-minute timeout.");
            contactTimeoutRef.current = setTimeout(() => {
                if (parseInt(sessionStorage.getItem("contactPopupCount")) === 1) {
                    setshowContactUs(true);
                    sessionStorage.setItem("contactPopupCount", 2);
                } else {
                }
            }, 300000);
        }
    };

    const firstTimeout = setTimeout(() => {
        console.log("50-second timeout triggered.");
        showAndStartOneTimeInterval();
    }, 50000);

    return () => {
        console.log("Cleanup: Clearing timeouts.");
        clearTimeout(firstTimeout);
        if (contactTimeoutRef.current) {
            clearTimeout(contactTimeoutRef.current);
        }
    };
}, [setshowContactUs]);

// ... rest of your component ...
  

  useEffect(() => {
    const handleCart = async () => {
      const storedCart = JSON.parse(localStorage.getItem("cart"));

      try {
        if (storedCart?._id && storedCart.hasOwnProperty("isEmi")) {
          // Fetch existing cart
          const response = await axios.get(`cart/read/${storedCart._id}`);
          const fetchedCart = response?.data;
          if (fetchedCart?.success && fetchedCart.hasOwnProperty("_id")) {
            localStorage.setItem("cart", JSON.stringify(fetchedCart));
          }
        } else {
          localStorage.removeItem("cart");
          await createCart();
        }
      } catch (error) {
        if (error.response?.status === 404 && error.response.data?.success === false) {
          localStorage.removeItem("cart");
          await createCart();
        } else {
          console.error("Error fetching cart:", error);
        }
      }
    };

    const createCart = async () => {
      try {
        const response = await axios.post(`cart/create`);
        const createdCart = response?.data?.result;
        if (createdCart?._id) {
          createdCart.isEmi = true;
          localStorage.setItem("cart", JSON.stringify(createdCart));
        }
      } catch (error) {
        console.error("Failed to create cart:", error);
      }
    };

    handleCart();
  }, []); 

  usePageTitle();

  const storeUTMParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");
  
    if (utmSource) {
      localStorage.setItem("utm_source", utmSource);
      localStorage.setItem("utm_medium", utmMedium);
      localStorage.setItem("utm_campaign", utmCampaign);
    }
  };

  useEffect(() => {
    storeUTMParams();
  }, []);

  return (
    <div>
      {location.pathname === "/user-info" ? (
        <></>
      ) : (
        <Flex minH={"100vh"} flexDirection={"column"} fontFamily={"Poppins, sans-serif"}>
          <TawkToScript />
          <Box>
            <Navbar />
          </Box>
          <Box flex="1">
            <Box minH={"calc(100vh - 100px)"}>
              <main>
                <Outlet />
              </main>
            </Box>
          </Box>
          <Popup show={showPopup} onClose={() => setShowPopup(false)} />
          {/* <ContactPopUp show={showContactUs} onClose={() => setshowContactUs(false)} /> */}
          <Box as="footer">
            <Footer />
          </Box>
        </Flex>
      )}
    </div>
  );
}

export default App;
