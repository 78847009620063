import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useForm, ValidationError } from "@formspree/react";
import { useToast } from "../../../components/Toast/toast";
import { useNavigate } from "react-router-dom";

const ContactPageModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
    page,
    utm_source,
    utm_medium,
    utm_id,
    utm_campaign,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
      page,
      utm_source,
      utm_medium,
      utm_id,
      utm_campaign,
    });
    return response.data;
  };

  const [state, handleSubmit] = useForm("xeqypwnz");
  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;
    const page = window.location.pathname; 
    const utm_source = localStorage.getItem("utm_source") || "direct";
  const utm_medium = localStorage.getItem("utm_medium") || "none";
  const utm_id = localStorage.getItem("utm_id") || "none";
  const utm_campaign = localStorage.getItem("utm_campaign") || "none";
    try {
      await mutate({ firstname, lastname, email, phone, message,page, utm_source, utm_medium, utm_id, utm_campaign
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { addToast } = useToast();
  const handleCombinedSubmit = async (e) => {
    await handleSubmit(e); // Trigger useForm's handleSubmit
    addToast(
      "We have received your query, please rest assured our team will get back to you",
      "success"
    );
    await handleSubmitForm(e);
    e.target.reset();
    setIsModalOpen(false);
    navigate("/thank-you");
  };

  return (
    <>
      <button
        onClick={toggleModal}
        className="bg-[#FF3131] text-white py-2 px-12 rounded-2xl text-base sm:text-lg font-medium hover:bg-red-600">
        Enquire Now
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-[#1B2438] w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl rounded-xl overflow-hidden mx-2">
            {/* Modal Header */}
            <div className="flex justify-between items-center px-4 py-3 border-b border-gray-700">
              <h2 className="text-base text-white font-semibold">Contact Us</h2>
              <button
                onClick={toggleModal}
                className="text-white text-xl font-bold hover:text-red-600">
                &times;
              </button>
            </div>

            {/* Modal Form */}
            <form
              onSubmit={handleCombinedSubmit}
              data-clarity-unmask="true"
              className="space-y-1 bg-[#1B2438] px-4 py-2 overflow-y-auto"
              style={{
                maxHeight: "85vh",
              }}>
              {/* First Name */}
              <div>
                <label
                  className="block text-sm text-gray-300 mb-1"
                  htmlFor="firstname">
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Your first name"
                  required
                  className="w-full p-2 bg-[#1B2438] border border-gray-600 rounded text-sm text-white"
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  className="block text-sm text-gray-300 mb-1"
                  htmlFor="lastname">
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Your last name"
                  required
                  className="w-full p-2 bg-[#1B2438] border border-gray-600 rounded text-sm text-white"
                />
              </div>

              {/* Phone */}
              <div>
                <label
                  className="block text-sm text-gray-300 mb-1"
                  htmlFor="phone">
                  Phone<span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Your phone number"
                  required
                  className="w-full p-2 bg-[#1B2438] border border-gray-600 rounded text-sm text-white"
                />
              </div>

              {/* Email */}
              <div>
                <label
                  className="block text-sm text-gray-300 mb-1"
                  htmlFor="email">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your email id"
                  required
                  className="w-full p-2 bg-[#1B2438] border border-gray-600 rounded text-sm text-white"
                />
              </div>

              {/* Message */}
              <div>
                <label
                  className="block text-sm text-gray-300 mb-1"
                  htmlFor="message">
                  Message (Optional)
                </label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Please drop your query, we would love to help you"
                  className="w-full p-2 bg-[#1B2438] border border-gray-600 rounded text-sm text-white"
                  rows="3"></textarea>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={state.submitting}
                className="w-full bg-[#188E00] hover:bg-green-700 text-sm text-white py-2 rounded shadow-inner">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactPageModal;
